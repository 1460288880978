import UsedCard from "./UsedCard";
import sc4040 from "../images/used/IMG_1384_Matthew Baird.jpeg";
import h30ct from "../images/used/IMG_1385_Matthew Baird.jpeg";
import fgu25 from "../images/used/IMG_1386_Matthew Baird.jpeg";
import hyundai_25l_7a from "../images/used/IMG_1387_Matthew Baird.png";
import hyundai_20bt_9u from "../images/used/IMG_1389_Matthew Baird.jpeg";
import big_joe_e30 from "../images/used/IMG_1390_Matthew Baird.jpeg";
import linde_h60d from "../images/used/IMG_4603.jpg";
import caterpillar_ep20 from "../images/used/IMG_4707.jpg";
import hyundai_hlf18c_5 from "../images/used/IMG_5157.jpg";

function UsedCards() {

    // const cardOneTitle = "6000lb Capacity Propane Forklift";

    // const cardOneText = "- One only\n\
    //     - Kion Baoli QD30\n\
    //     - 6000lb lift capacity\n\
    //     - Side shift\n\
    //     - New Forks\n\
    //     - Completely reconditioned\n\
    //     - Pneumatic Tires\n\
    //     - *New Annual Inspection\n\
    //     - Warranty Included\n\
    //     Stop by and take this for a lift today!\n\
    //     ";

    // const cardOneImages = [
    //     kionBaoli1,
    //     kionBaoli2
    // ];

    const cardsData = [
        {
            title: "Crown SC4040",
            text: "- Capacity: 3000lb\n" +
                "- Fuel Type: Electric\n" +
                "- Mast Height: 185\n" +
                "- Accessory: Side shift\n" +
                "- Tire Type: Cushion\n",
            images: [sc4040],
            sold: true,
        },
        {
            title: "Linde H30CT",
            text: "- Capacity: 6000lb\n" +
                "- Fuel Type: Propane\n" +
                "- Mast Height: 185\n" +
                "- Accessory: Side shift\n" +
                "- Tire Type: Solid\n",
            images: [h30ct],
            sold: false,
        },
        {
            title: "Toyota 8FGU25",
            text: "- Capacity: 5000lb\n" +
                "- Fuel Type: Propane\n" +
                "- Mast Height: 131\n" +
                "- Accessory: Side shift\n" +
                "- Tire Type: Pneumatic\n",
            images: [fgu25],
            sold: true,
        },
        {
            title: "Hyundai 25L-7A",
            text: "- Capacity: 5000lb\n" +
                "- Fuel Type: Propane\n" +
                "- Mast Height: 185\n" +
                "- Accessory: Side shift\n" +
                "- Tire Type: Pneumatic\n",
            images: [hyundai_25l_7a],
            sold: false,
        },
        {
            title: "Hyundai 20BT-9U",
            text: "- Capacity: 4000lb\n" +
                "- Fuel Type: Electric\n" +
                "- Mast Height: 185\n" +
                "- Accessory: Side shift\n" +
                "- Tire Type: Solid\n",
            images: [hyundai_20bt_9u],
            sold: false,
        },
        {
            title: "Big Joe E30",
            text: "- Capacity: 3000lb Pallet Jack\n" +
                "- Fuel Type: Electric\n" +
                "- Tire Type: Other\n",
            images: [big_joe_e30],
            sold: false,
        },
        {
            title: "Linde H60D",
            text: "- Capacity: 13,000lbs\n" +
                "- Fuel Type: Diesel\n" +
                "- Mast Height: 120\n" +
                "- Tire Type: Pneumatic\n",
            images: [linde_h60d],
            sold: false,
        },
        {
            title: "Caterpillar EP20",
            text: "- Capacity: 4,000lbs\n" +
                "- Fuel Type: Electric\n" +
                "- Accessory: Side Shift\n" +
                "- Tire Type: Solid\n",
            images: [caterpillar_ep20],
            sold: false,
        },
        {
            title: "Hyundai HLF18C-5",
            text: "- Capacity: 3,600lbs\n" +
                "- Fuel Type: LPG/Propane\n" +
                "- Mast Height: 185\n" +
                "- Tire Type: Cushion\n",
            images: [hyundai_hlf18c_5],
            sold: false,
        },
    ];

    return (
        <div className="flex flex-col items-center my-5">
            <div className="grid grid-cols-1 md:grid-cols-3 my-5 gap-5">
                {/* <UsedCard images={cardOneImages} title={cardOneTitle} text={cardOneText} /> */}
                {cardsData.map((card, index) => (
                    <UsedCard key={index} images={card.images} title={card.title} text={card.text} sold={card.sold} />
                ))}
            </div>
        </div>
    );
}

export default UsedCards;