import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

function PartsFilter() {
    const dispatch = useDispatch();
    const { parts } = useSelector(({ parts: { data, searchTerm }}) => {
        const filteredParts= data.filter((part) => part.name.toLowerCase().includes(searchTerm.toLowerCase()) || part.brand.toLowerCase().includes(searchTerm.toLowerCase()));

        return { parts: filteredParts };
    });
    const [checked, setChecked] = useState(false);

    const handleChange = () => {
      setChecked(!checked);
    };


    const getUniqueBrands = () => {
        const brands = parts.map((part) => {
            return <div><input className="mx-2" type="checkbox" checked={checked} onChange={handleChange}/>{part.brand}</div>;
        });
        return (<div>{brands}</div>);
    }

    const getUniqueCategories = () => {
        return (<div></div>);
    }

    return (
        <div>
            <h3>Filter</h3>
            <div>
                <h3 className="font-bold">Brand</h3>
                {getUniqueBrands()}
            </div>
            <div>
                <h3 className="font-bold">Price</h3>
                <div>
                    <div className="my-2">
                        <label>Min</label>
                        <input className="border-2 mx-2" type="number"/>
                    </div>
                    <div className="my-2">
                        <label>Max</label>
                        <input className="border-2 mx-2" type="number"/>
                    </div>
                </div>
            </div>
            <div>
                <h3 className="font-bold">Category</h3>
                {getUniqueCategories()}
            </div>
        </div>
    );
}

export default PartsFilter;