// BigJoePage.jsx

import { useState, useEffect } from 'react';
import Splash from "../components/Splash";
import bgImg from "../images/BigJoeBanner.jpg"; // Update this path to your Big Joe banner image
import Tab from "../components/Tab";
import { Link } from "react-router-dom";

function BigJoePage() {
    const [activeTab, setActiveTab] = useState("Move");
    const [equipmentData, setEquipmentData] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://www.hurricanelift.com:5000/equipment?vendor=Big%20Joe');

                if (!response.ok) {
                    console.log('Error fetching data');
                } else {
                    const data = await response.json();

                    const equipmentByType = {
                        Move: data.equipment.filter((equipment) => equipment.type === 'Move'),
                        Stack: data.equipment.filter((equipment) => equipment.type === 'Stack'),
                        Access: data.equipment.filter((equipment) => equipment.type === 'Access'),
                        Automate: data.equipment.filter((equipment) => equipment.type === 'Automate'),
                    };

                    setEquipmentData(equipmentByType);
                }
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
    }, []);

    const text = (
        <h1 className="text-6xl xl:text-7xl 2xl:text-9xl uppercase text-white font-bold drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)] md:drop-shadow-none">
            Big Joe
        </h1>
    );

    return (
        <div>
            <Splash image={bgImg} text={text} />
            <div className='mx-auto flex items-center justify-center'>
                <Tab name={"Move"} activeTab={activeTab} setActiveTab={setActiveTab} />
                <Tab name={"Stack"} activeTab={activeTab} setActiveTab={setActiveTab} />
                <Tab name={"Access"} activeTab={activeTab} setActiveTab={setActiveTab} />
                <Tab name={"Automate"} activeTab={activeTab} setActiveTab={setActiveTab} />
            </div>
            <div>
                {activeTab === "Move" && equipmentData['Move'] &&
                    <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-6 gap-4 w-full md:w-1/2 mx-auto my-10">
                        {equipmentData['Move'].map((equipment) => (
                            <div key={equipment.id} className="hover:drop-shadow-[0_10px_10px_rgba(0,0,0,.5)] rounded-xl overflow-hidden">
                                <Link to={`/bigjoe/${equipment.link.replace(/\//g, '--')}`} className="flex flex-col items-center justify-center font-bold uppercase border border-gray-400 border-b-2 border-x-0 border-t-0 p-5 gap-10 w-full h-full bg-white">
                                    <img src={`https://www.hurricanelift.com:5000${equipment.image}`}
                                        alt={equipment.name} className="w-full h-full shrink border-red-500 border rounded-lg object-scale-down" />
                                    {equipment.name}
                                </Link>
                            </div>
                        ))}
                    </div>
                }
                {activeTab === "Stack" && equipmentData['Stack'] &&
                    <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-6 gap-4 w-full md:w-1/2 mx-auto my-10">
                        {equipmentData['Stack'].map((equipment) => (
                            <div key={equipment.id} className="hover:drop-shadow-[0_10px_10px_rgba(0,0,0,.5)] rounded-xl overflow-hidden">
                                <Link to={`/bigjoe/${equipment.link.replace(/\//g, '--')}`} className="flex flex-col items-center justify-center font-bold uppercase border border-gray-400 border-b-2 border-x-0 border-t-0 p-5 gap-10 w-full h-full bg-white">
                                    <img src={`https://www.hurricanelift.com:5000${equipment.image}`}
                                        alt={equipment.name} className="w-full h-full shrink border-red-500 border rounded-lg object-scale-down" />
                                    {equipment.name}
                                </Link>
                            </div>
                        ))}
                    </div>
                }
                {activeTab === "Access" && equipmentData['Access'] &&
                    <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-6 gap-4 w-full md:w-1/2 mx-auto my-10">
                        {equipmentData['Access'].map((equipment) => (
                            <div key={equipment.id} className="hover:drop-shadow-[0_10px_10px_rgba(0,0,0,.5)] rounded-xl overflow-hidden">
                                <Link to={`/bigjoe/${equipment.link.replace(/\//g, '--')}`} className="flex flex-col items-center justify-center font-bold uppercase border border-gray-400 border-b-2 border-x-0 border-t-0 p-5 gap-10 w-full h-full bg-white">
                                    <img src={`https://www.hurricanelift.com:5000${equipment.image}`}
                                        alt={equipment.name} className="w-full h-full shrink border-red-500 border rounded-lg object-scale-down" />
                                    {equipment.name}
                                </Link>
                            </div>
                        ))}
                    </div>
                }
                {activeTab === "Automate" && equipmentData['Automate'] &&
                    <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-6 gap-4 w-full md:w-1/2 mx-auto my-10">
                        {equipmentData['Automate'].map((equipment) => (
                            <div key={equipment.id} className="hover:drop-shadow-[0_10px_10px_rgba(0,0,0,.5)] rounded-xl overflow-hidden">
                                <Link to={`/bigjoe/${equipment.link.replace(/\//g, '--')}`} className="flex flex-col items-center justify-center font-bold uppercase border border-gray-400 border-b-2 border-x-0 border-t-0 p-5 gap-10 w-full h-full bg-white">
                                    <img src={`https://www.hurricanelift.com:5000${equipment.image}`}
                                        alt={equipment.name} className="w-full h-full shrink border-red-500 border rounded-lg object-scale-down" />
                                    {equipment.name}
                                </Link>
                            </div>
                        ))}
                    </div>
                }
            </div>
        </div>
    );
}

export default BigJoePage;
