import React from 'react';
import Navbar from './components/Navbar';
import { Routes, Route, Outlet, Link } from "react-router-dom";
import MainPage from './pages/MainPage';
import ContactPage from './pages/ContactPage';
import TrainingPage from './pages/TrainingPage';
import "./css/Calendar.less";
import PartsPage from './pages/PartsPage';
import PartsPageWIP from './pages/PartsPageWIP';
import ProxyTestPage from './pages/ProxyTestPage';
import ProxyTestPageHyundai from './pages/ProxyTestPageHyundai';
import HyundaiPage from './pages/HyundaiPage';
import RentalsPage from './pages/RentalsPage';
import RepairsAndServicePage from './pages/RepairsAndServicePage';
import UsedPage from './pages/UsedPage';
import AboutUsPage from './pages/AboutUsPage';
import StaffDirectoryPage from './pages/StaffDirectoryPage';
import HyundaiEquipmentPage from './pages/HyundaiEquipmentPage';
import LoadLifterPage from './pages/LoadLifterPage';
import LoadLifterEquipmentPage from './pages/LoadLifterEquipmentPage';
import LindePage from './pages/LindePage';
import LindeEquipmentPage from './pages/LindeEquipmentPage';
import HyundaiConstructionPage from './pages/HyundaiConstructionPage';
import HyundaiConstructionEquipmentPage from './pages/HyundaiConstructionEquipmentPage';
import BigJoePage from './pages/BigJoePage';
import BigJoeEquipmentPage from './pages/BigJoeEquipmentPage';

function App() {
  return (
    <div className="row" id="navbar-container">
      <Routes>
        <Route path="/" element={<Navbar />}>
          <Route path="/" element={<MainPage />}></Route>
          <Route path="about-us" element={<AboutUsPage />}></Route>
          <Route path="contact-us" element={<ContactPage />}></Route>
          <Route path="hyundai" element={<HyundaiPage />}></Route>
          <Route path="hyundaiconstruction" element={<HyundaiConstructionPage />}></Route>
          <Route path="linde" element={<LindePage />}></Route>
          <Route path="loadlifter" element={<LoadLifterPage />}></Route>
          <Route path="bigjoe" element={<BigJoePage />}></Route>
          <Route path="used" element={<UsedPage />}></Route>
          <Route path="repairsandservice" element={<RepairsAndServicePage />}></Route>
          <Route path="parts" element={<PartsPage />}></Route>
          <Route path="partsWIP" element={<PartsPageWIP />}></Route>
          <Route path="staffdirectory" element={<StaffDirectoryPage />}></Route>
          <Route path="rentals" element={<RentalsPage />}></Route>
          <Route path="training" element={<TrainingPage />}></Route>
          <Route path="hyundai/:productId" element={<HyundaiEquipmentPage />}></Route>
          <Route path="hyundaiconstruction/:productId" element={<HyundaiConstructionEquipmentPage />}></Route>
          <Route path="loadlifter/:productId" element={<LoadLifterEquipmentPage />}></Route>
          <Route path="linde/:productId" element={<LindeEquipmentPage />}></Route>
          <Route path="bigjoe/:productId" element={<BigJoeEquipmentPage />}></Route>
        </Route>
      </Routes>
    </div>
  );
}

export default App;