import PartsList from "../components/PartsList";
import PartsSearch from "../components/PartsSearch";
import { useDispatch, useSelector } from "react-redux";
import { addPart } from "../store";
import { useEffect } from "react";
import PartsFilter from "../components/PartsFilter";

function PartsPageWIP() {

    const dispatch = useDispatch();

    //Debugging purposes only begin
    //This generates a temporary list of parts until we can get it from an API
    const generateParts = () => {
        dispatch(addPart(
            {
                brand: "Bluewater Lubricants Limited",
                name: "10W30",
                item: "10W30",
                description: "Oil, 10W30",
                cost: 10,
            },
        ));
        dispatch(addPart(
            {
                brand: "TVH Canada Ltd",
                name: "Air Filter",
                item: "11FK-20080",
                description: "Filter, Outer Air",
                cost: 100,
            },
        ));
        dispatch(addPart(
            {
                brand: "Linde Material Handling",
                name: "Wheel",
                item: "11508501103",
                description: "Wheel, Load c\\w bearings MT20",
                cost: 5,
            }
        ));
        dispatch(addPart(
            {
                brand: "Hyundai Construction Equipment",
                name: "Radiator Assembly",
                item: "11HA-30170",
                description: "Radiator Assembly (-7\\-7A)",
                cost: 30,
            },
        ));
        dispatch(addPart(
            {
                brand: "Stellar Industrial Sales Limited",
                name: "Fluid Film",
                item: "3300",
                description: "Fluid Film, 333G Aerosol Can",
                cost: 10,
            },
        ));
    }

    useEffect(() => {
        generateParts();
    }, []);
    //Debugging purposes only end


    return (
        <div className="grid grid-cols-8 mx-auto mx-10">
            <div className="col-span-1">
                <div>Parts</div>
                <PartsSearch />
                <PartsFilter />
            </div>
            <div className="mx-10 my-5 col-span-7">
                <PartsList />
            </div>
        </div>
    );
}

export default PartsPageWIP;