function PartsCard({ part }) {
    return (
        <div className='panel'>
            <div className="p-5 max-w-sm rounded overflow-hidden border-2">
                <div className="card border-1 text-center">
                    <div>
                        <img className="w-full my-3 border border-dark b-1" src={'https://www.salonlfc.com/wp-content/uploads/2018/01/image-not-found-1-scaled-1150x647.png'} alt=""/>
                    </div>
                    <div>
                        <p className="px-6 py-4 font-bold">{part.brand} - {part.name} - ${part.cost}</p>
                        <hr className="border border-slate-500 bg-slate-500" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PartsCard;