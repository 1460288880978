import { useDispatch, useSelector } from "react-redux";
import { changeSearchTerm } from "../store";

function PartsSearch() {
    const dispatch = useDispatch();
    const { searchTerm } = useSelector((state) => {
        return state.parts.searchTerm;
    });

    const handleSearchTermChange = (event) => {
        dispatch(changeSearchTerm(event.target.value));
    };

    return (
        <div className="list-header">
            <form>
                <div className="search field is-horizontal">
                        <label className="label">Search</label>
                        <input className="mx-2 w-3/5 border-2" value={searchTerm} onChange={handleSearchTermChange}/>
                </div>
            </form>
        </div>
    );
}

export default PartsSearch;