import { useDispatch, useSelector } from "react-redux";
import { removePart } from "../store";
import PartsCard from "./PartsCard";

function PartsList() {
    const dispatch = useDispatch();
    const { parts } = useSelector(({ parts: { data, searchTerm }}) => {
        const filteredParts= data.filter((part) => part.name.toLowerCase().includes(searchTerm.toLowerCase()) || part.brand.toLowerCase().includes(searchTerm.toLowerCase()));

        return { parts: filteredParts };
    });

    const handlePartDelete = (part) => {
        //dispatch(removePart(part.id));
    }

    const partsList = parts.map((part) => {
        //const bold = name && part.name.toLowerCase().includes(name.toLowerCase());
        const bold = false;
        return (
            <div key={part.id} className={`panel ${bold && 'bold'}`}>
                <PartsCard part={part} />
            </div>
        );
    });

    return <div className="part-list grid grid-flow-rows auto-cols-max grid-cols-4 gap-4">{partsList}<hr /></div>
}

export default PartsList;