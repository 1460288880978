// import { useState, useEffect } from 'react';
// import { useParams } from "react-router-dom";

// function LindeEquipmentPage() {
//     const { productId } = useParams();
//     const [iframeSrc, setIframeSrc] = useState('');
//     const proxyUrl = 'https://www.hurricanelift.com:5000/linde-proxy';
//     let targetUrl = `https://www.linde-mh.us/products/${productId.replace(/--/g, '/')}`;

//     useEffect(() => {
//         console.log(`Loading ${targetUrl} via proxy`);
//         // Directly set the iframe to load through our fully rewriting proxy
//         const iframeUrl = proxyUrl + '?url=' + encodeURIComponent(targetUrl);
//         setIframeSrc(iframeUrl);
//     }, [productId]);

//     return (
//         <iframe
//             title="Linde Product"
//             src={iframeSrc}
//             style={{ height: '100vh', width: '100%', border: 'none' }}
//             className="mx-auto my-10 mt-30"
//         ></iframe>
//     );
// }

// export default LindeEquipmentPage;



import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";

function LindeEquipmentPage() {
    const { productId } = useParams();
    const [iframeSrc, setIframeSrc] = useState('');
    const proxyUrl = 'https://www.hurricanelift.com:5000/linde-proxy';
    let targetUrl = `https://www.linde-mh.us/products/${productId}`;
    useEffect(() => {
        console.log(`Fetching from ${targetUrl}`);
        targetUrl = targetUrl.replace(/--/g, '/');
        fetch(proxyUrl + '?url=' + encodeURIComponent(targetUrl))
        .then((response) => {
            return response.text();
        })
        .then((content) => {
            console.log(content);
            content = content.replace(/<img[^>]*?src\s*=\s*"[^"]*?www\.facebook\.com[^"]*?"[^>]*?\/?>/g, '');

            const parser = new DOMParser();
            const doc = parser.parseFromString(content, 'text/html');

            // Remove the elements you want
            const header = doc.querySelector('.header');
            const getAQuoteFooter = doc.querySelector('.get_a_quote_footer');
            const dealer = doc.querySelector('.product_header_left p');
            const footer = doc.querySelector('footer');
            const footerClass = doc.querySelector('.footer');
            const productTabs = doc.querySelector('.product_tabs');
            const cookieBanner = doc.querySelector('.ccm-root');
            const findADealerButton = doc.querySelector('.text-and-button__button');
            const navbar = doc.querySelector('.aem-GridColumn.aem-GridColumn--default--12');
            const pagekicker = doc.querySelector('.page-title__kicker');
            const jumpMenu = doc.querySelector('.jump-menu');
            const cardGrid = doc.querySelector('.card-grid');
            const prefooter = doc.querySelector('.prefooter');

            if (header) header.remove();
            if (getAQuoteFooter) getAQuoteFooter.remove();
            if (dealer) dealer.remove();
            if (footer) footer.remove();
            if (footerClass) footerClass.remove();
            if (productTabs) productTabs.remove();
            if (cookieBanner) cookieBanner.remove();
            if (findADealerButton) findADealerButton.remove();
            if (navbar) navbar.remove();
            if (pagekicker) pagekicker.remove();
            if (jumpMenu) jumpMenu.remove();
            if (cardGrid) cardGrid.remove();
            if (prefooter) prefooter.remove();

            // Serialize the modified DOM object back to a string
            const serializer = new XMLSerializer();
            const modifiedContent = serializer.serializeToString(doc);

            const blob = new Blob([modifiedContent], { type: 'text/html' });
            const blobUrl = URL.createObjectURL(blob);
            setIframeSrc(blobUrl);
        })
        .catch((error) => {
            console.error(`Problem with fetch request: ${error.message}`);
            return (<div></div>);
        });
    }, []);

    return (
        <iframe
            title="fetched-content"
            src={iframeSrc}
            style={{ height: '100vh', width: '66%', border: 'none' }}
            className="mx-auto my-10 mt-30"
        ></iframe>
  );
}

export default LindeEquipmentPage;