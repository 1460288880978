import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";

function BigJoeEquipmentPage() {
    const { productId } = useParams();
    const [iframeSrc, setIframeSrc] = useState('');
    const proxyUrl = 'https://www.hurricanelift.com:5000/proxy';
    const targetUrl = `https://bigjoeforklifts.com/products/${productId.replace(/--/g, '/')}`;

    useEffect(() => {
        fetch(proxyUrl + '?url=' + encodeURIComponent(targetUrl))
            .then((response) => response.text())
            .then((content) => {
                // Parse the HTML content
                const parser = new DOMParser();
                const doc = parser.parseFromString(content, 'text/html');

                // Remove unwanted elements
                const elementsToRemove = [
                    'header',
                    'footer',
                    'nav',
                    '.mobile-nav-wrapper',
                    '.announcement-bar',
                    '.shopify-chat-button',
                    '#shopify-section-header',
                    '#shopify-section-footer',
                    '#NavDrawer',
                    '#MobileNav',
                    '#SearchDrawer',
                    '#PageContainer > .topbar',
                    '.product-buttons',
                    '.MagicToolboxMessage',
                ];

                elementsToRemove.forEach((selector) => {
                    if (selector.localeCompare('.product-buttons') !== 0) {
                        const elements = doc.querySelectorAll(selector);
                        elements.forEach((el) => el.remove());
                    }
                    else {
                        const txts = doc.querySelectorAll(selector);
                        txts.forEach((text) => {

                            if(text.textContent.toLowerCase().includes('pricing')) {
                        
                                text.remove();
                        
                          }
                        });
                    }
                });

                // Remove iframes (e.g., embedded videos)
                const iframes = doc.querySelectorAll('iframe');
                iframes.forEach(iframe => iframe.remove());

                // Remove unnecessary scripts but keep the one containing 'openCity'
                const scriptTags = doc.querySelectorAll('script');
                scriptTags.forEach((scriptTag) => {
                    const src = scriptTag.getAttribute('src');
                    const innerText = scriptTag.textContent;

                    if (innerText && innerText.includes('function openCity')) {
                        // Keep the script containing 'openCity' function
                    } else if (
                        (src && (src.includes('jquery') || src.includes('shopify') || src.includes('analytics'))) ||
                        innerText.includes('ShopifyAnalytics') ||
                        innerText.includes('CartJS')
                    ) {
                        scriptTag.remove();
                    } else {
                        // Remove other scripts to prevent conflicts
                        scriptTag.remove();
                    }
                });

                // Ensure jQuery is included
                const jQueryScript = doc.createElement('script');
                jQueryScript.src = 'https://ajax.googleapis.com/ajax/libs/jquery/1.11.0/jquery.min.js';
                doc.head.appendChild(jQueryScript);

                // Adjust relative URLs to absolute URLs
                const updateAttributeUrls = (selector, attribute) => {
                    const elements = doc.querySelectorAll(selector);
                    elements.forEach((element) => {
                        const attrValue = element.getAttribute(attribute);
                        if (attrValue) {
                            // Handle protocol-relative URLs
                            if (attrValue.startsWith('//')) {
                                element.setAttribute(attribute, 'https:' + attrValue);
                            }
                            // Handle relative URLs
                            else if (attrValue.startsWith('/')) {
                                element.setAttribute(attribute, 'https://bigjoeforklifts.com' + attrValue);
                            }
                        }
                    });
                };

                updateAttributeUrls('img', 'src');
                updateAttributeUrls('a', 'href');
                updateAttributeUrls('link', 'href');
                updateAttributeUrls('script', 'src');

                // Remove CSP meta tags
                const cspMetaTags = doc.querySelectorAll('meta[http-equiv="Content-Security-Policy"]');
                cspMetaTags.forEach((tag) => tag.remove());

                // Serialize the modified DOM object back to a string
                const serializer = new XMLSerializer();
                let modifiedContent = serializer.serializeToString(doc);
                console.log(modifiedContent);
                // Post-process the HTML to fix escaped characters within script tags
                modifiedContent = fixEscapedScripts(modifiedContent);
                console.log(modifiedContent);
                const blob = new Blob([modifiedContent], { type: 'text/html' });
                const blobUrl = URL.createObjectURL(blob);
                setIframeSrc(blobUrl);
            })
            .catch((error) => {
                console.error(`Problem with fetch request: ${error.message}`);
            });
    }, [productId]);

    // Function to fix escaped characters within script tags
    function fixEscapedScripts(htmlContent) {
        // Use a regular expression to find all <script>...</script> blocks
        return htmlContent.replace(/<script[^>]*>([\s\S]*?)<\/script>/gi, function(match, p1) {
            // Unescape the script content
            const unescapedScript = p1
                .replace(/&lt;/g, '<')
                .replace(/&gt;/g, '>')
                .replace(/&amp;/g, '&')
                .replace(/&quot;/g, '"')
                .replace(/&#39;/g, "'");
            return `<script>${unescapedScript}</script>`;
        });
    }

    return (
        <iframe
            title="Big Joe Product"
            src={iframeSrc}
            style={{ height: '100vh', width: '100%', border: 'none' }}
            className="mx-auto my-10 mt-30"
        ></iframe>
    );
}

export default BigJoeEquipmentPage;
