import { useState, useEffect } from 'react';
import Splash from "../components/Splash";
import bgImg from "../images/Top-Image-Linde-e1638150520682.jpg";
import Tab from "../components/Tab";
import { Link } from "react-router-dom";

function LindePage() {
    const [activeTab, setActiveTab] = useState("Electric Counterbalanced");
    const [equipmentData, setEquipmentData] = useState({});

    const tabToKeyMap = {
        "Electric Counterbalanced Cushioned Tire": "CounterbalancedCushioned",
        "Electric Narrow Aisle Pneumatic Tire": "CounterbalancedPneumatic",
        "Narrow Aisle Reach": "NarrowReach",
        "Narrow Aisle Turret": "NarrowTurret",
        "Order Picker": "OrderPicker",
        "Int Combustion Cushion Tires": "ICCushion",
        "Int Combustion Pneumatic Tire": "ICPneumatic",
        "Tow Trucks": "Tow",
        "Pallet Stacker": "PalletStacker",
        "Pallet Truck": "PalletTruck"
    };


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://www.hurricanelift.com:5000/equipment?vendor=Linde');

                if (!response.ok) {
                    console.log('Error fetching data');
                } else {
                    const data = await response.json();

                    // const equipmentByType = {
                    //     Counterbalanced: data.equipment.filter((equipment) => equipment.type === 'Counterbalanced'),
                    //     Narrow: data.equipment.filter((equipment) => equipment.type === 'Narrow Aisle'),
                    //     Warehouse: data.equipment.filter((equipment) => equipment.type === 'Warehouse'),
                    //     Cushion: data.equipment.filter((equipment) => equipment.type === 'Cushion Tires'),
                    //     Pneumatic: data.equipment.filter((equipment) => equipment.type === 'Pneumatic Tires'),
                    //     Tow: data.equipment.filter((equipment) => equipment.type === 'Tow Truck'),
                    // };

                    const equipmentByType = {
                        CounterbalancedCushioned: data.equipment.filter((equipment) => equipment.type === 'Electric Counterbalanced: Cushion Tire Electric Forklifts'),
                        CounterbalancedPneumatic: data.equipment.filter((equipment) => equipment.type === 'Electric Counterbalanced: Pneumatic Tire Electric Forklifts'),
                        NarrowReach: data.equipment.filter((equipment) => equipment.type === 'Narrow Aisle Reach: Unlock efficient storage'),
                        NarrowTurret: data.equipment.filter((equipment) => equipment.type === 'Narrow Aisle Turret: Make the most of your space'),
                        OrderPicker: data.equipment.filter((equipment) => equipment.type === 'Order Picker: Maximize your order fulfillment efficiency'),
                        ICCushion: data.equipment.filter((equipment) => equipment.type === 'Internal Combustion Counterbalanced: Cushion Tire Internal Combustion Forklifts'),
                        ICPneumatic: data.equipment.filter((equipment) => equipment.type === 'Internal Combustion Counterbalanced: Pneumatic Tire Internal Combustion Forklifts'),
                        Tow: data.equipment.filter((equipment) => equipment.type === 'Tow Tractors: Pulling efficiency into your workflow'),
                        PalletStacker: data.equipment.filter((equipment) => equipment.type === 'Pallet Stacker: Master maneuverability'),
                        PalletTruck: data.equipment.filter((equipment) => equipment.type === 'Pallet Truck: Your go-to for ground-level efficiency'),
                    };
                    setEquipmentData(equipmentByType);
                }
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
    }, []);


    const text = <h1 className="text-6xl xl:text-7xl 2xl:text-9xl uppercase text-white font-bold drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)] md:drop-shadow-none">Linde</h1>;

    return (
        <div>
            <Splash image={bgImg} text={text} />
            <div className='mx-auto flex items-center justify-center px-10 w-3/4'>
                <Tab name={"Electric Counterbalanced Cushioned Tire"} activeTab={activeTab} setActiveTab={setActiveTab} />
                <Tab name={"Electric Narrow Aisle Pneumatic Tire"} activeTab={activeTab} setActiveTab={setActiveTab} />
                <Tab name={"Narrow Aisle Reach"} activeTab={activeTab} setActiveTab={setActiveTab} />
                <Tab name={"Narrow Aisle Turret"} activeTab={activeTab} setActiveTab={setActiveTab} />
                <Tab name={"Order Picker"} activeTab={activeTab} setActiveTab={setActiveTab} />
                <Tab name={"Int Combustion Cushion Tires"} activeTab={activeTab} setActiveTab={setActiveTab} />
                <Tab name={"Int Combustion Pneumatic Tire"} activeTab={activeTab} setActiveTab={setActiveTab} />
                <Tab name={"Tow Trucks"} activeTab={activeTab} setActiveTab={setActiveTab} />
                <Tab name={"Pallet Stacker"} activeTab={activeTab} setActiveTab={setActiveTab} />
                <Tab name={"Pallet Truck"} activeTab={activeTab} setActiveTab={setActiveTab} />
            </div>
            <div>
                {equipmentData[tabToKeyMap[activeTab]] && (
                    <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-6 gap-4 w-full md:w-1/2 mx-auto my-10">
                        {equipmentData[tabToKeyMap[activeTab]].map((equipment) => (
                            <div key={equipment.id} className="hover:drop-shadow-[0_10px_10px_rgba(0,0,0,.5)] rounded-xl overflow-hidden">
                                <Link
                                    to={`/linde/${equipment.link}`}
                                    className="flex flex-col items-center justify-center font-bold uppercase border border-gray-400 border-b-2 border-x-0 border-t-0 p-5 gap-10 w-full h-full bg-white"
                                >
                                    <img
                                        src={`https://www.hurricanelift.com:5000${equipment.image}`}
                                        alt={equipment.name}
                                        className="w-full h-full shrink border-red-500 border rounded-lg object-scale-down"
                                    />
                                    {equipment.name}
                                </Link>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
}

export default LindePage;